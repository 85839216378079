.ck.ck-editor__editable > .ck-placeholder::before {
  color: grey;
  font-family: sans-serif;
  font-size: 14px;
}

.ck-editor__editable {
  background-color: #cccccc57 !important;
}

.ck.ck-reset .ck-dropdown__panel {
  max-height: 300px;
  overflow-y: auto;
}

.ck.ck-reset .ck-dropdown__panel li > button > span {
  font-size: 12px !important;
}

.ck-balloon-panel_with-arrow {
  z-index: 10000 !important;
}
